import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { LOADING_MESSAGE } from "../../actions/types";
import AuthService from "../../services/auth.service";
import { displayMessage } from "./../common/common";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { addUserInOrganization } from "../../services/user.service";

const AddUserInOrganization = () => {
    const dispatch = useDispatch();
    let params = useParams();
    const [data, setData] = useState({});
    const { isLoading } = useSelector((state) => state.message, shallowEqual);

    useEffect(() => {
        const addUserInOrganizationFun = (orgId, uid) => {
            dispatch({ type: LOADING_MESSAGE, isLoading: true });
            addUserInOrganization(orgId, uid)
                .then((res) => {
                    setData(res.data);
                    dispatch({ type: LOADING_MESSAGE, isLoading: false });
                })
                .catch((error) => {
                    const message = error?.response?.data?.message || error?.message || error?.toString();;
                    dispatch(displayMessage("error", message));
                });
        };
        addUserInOrganizationFun(params.orgId, params.uid)
    }, []);

    return (
        <>
            <div className="MainLoginBg"></div>
            {Object.keys(data).length > 0 &&
                <div className="SigninWrapp">
                    <div className="text-center terms">
                        {data.status === 200 ?
                            <CheckCircleIcon style={{ color: "limegreen", fontSize: 100 }} />
                            :
                            <CancelOutlinedIcon style={{ color: "firebrick", fontSize: 100 }} />
                        }
                        <h3>{data.message}</h3>
                    </div>
                </div>
            }
            <Backdrop
                open={isLoading}
                style={{ zIndex: 99999999999, color: "#fff" }}
            >
                <CircularProgress />
            </Backdrop>
        </>
    );
};

export default AddUserInOrganization;
