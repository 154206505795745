import React, { useEffect } from "react";
import { forwardRef } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getEulaHistory,
  hardDeleteUser,
  activateUser,
  updateIssue,
  createUser,
} from "../../services/user.service";
import { Tooltip } from "antd";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  save: forwardRef((props, ref) => <DoneOutlineIcon {...props} ref={ref} />),

  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const EulaHistory = () => {
  const { useState } = React;
  const columns = [
    {
      title: "Version",
      field: "version",
      editable: "never",
    },
    {
      title: "Contents",
      editable: "never",
      field: "policy.value",
      render: (val) => {
        return (
          <Tooltip
            overlayStyle={{
              maxWidth: "500px",
              maxHeight: "400px",
              overflow: "scroll",
            }}
            title={val.policy.value}
          >
            {" "}
            {val.policy.value.slice(0, 200) + "..."}
          </Tooltip>
        );
      },
    },
    {
      title: "Date Updated",
      editable: "never",
      field: "createdAt",
      render: (rowData) => {
        return (
          rowData.createdAt.split("T")[0] +
          " " +
          rowData.createdAt.split("T")[1].substring(0, 8)
        );
      },
    },
  ];

  const [data, setData] = useState([]);

  const [backDropOpen, setBackDropOpen] = React.useState(true);
  useEffect(() => {
    getEulaHistory().then((res) => {
      setData(res.data.eulaPolicy);
      setBackDropOpen(false);
    });
  }, []);
  return (
    <div className="margindiv usersTable">
      <MaterialTable
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        icons={tableIcons}
        title="EULA History"
        columns={columns}
        data={data}
      />
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default EulaHistory;
