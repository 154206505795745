import React, { useEffect } from "react";
import { forwardRef } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getInactiveUsers,
  hardDeleteUser,
  activateUser,
  updateUser,
  createUser,
} from "../../services/user.service";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  save: forwardRef((props, ref) => <DoneOutlineIcon {...props} ref={ref} />),

  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validateField(val) {
  return val != "";
}

const Support = () => {
  const { useState } = React;
  const columns = [
    { title: "User ID", field: "id", editable: "never" },
    {
      title: "First Name",
      field: "firstName",

      // validate: (rowData) => {
      //   if (validateField(rowData.firstName)) {
      //   }

      //   return validateField(rowData.firstName) && rowData.firstName
      //     ? ""
      //     : "Name is required";
      // },
    },
    {
      title: "Last Name",
      field: "lasName",

      // validate: (rowData) => {
      //   if (validateField(rowData.lasName)) {
      //   }

      //   return validateField(rowData.lasName) && rowData.lasName
      //     ? ""
      //     : "Name is required";
      // },
    },
    {
      title: "Email",
      render: (col) => (
        <Link to={`/user-profile?user=${col.email}`}>{col.email}</Link>
      ),
      field: "email",
      editable: "onAdd",
      // validate: (rowData) => {
      //   if (validateEmail(rowData.email)) {
      //   }
      //   return validateEmail(rowData.email) ? "" : "Email is invalid";
      // },
    },

    {
      title: "Company",
      field: "company",
      // validate: (rowData) => {
      //   if (validateField(rowData.company)) {
      //   }

      //   return validateField(rowData.company) && rowData.company
      //     ? ""
      //     : "Company is required";
      // },
    },
    {
      title: "Location",
      field: "location",
      // validate: (rowData) => {
      //   if (validateField(rowData.location)) {
      //   }

      //   return validateField(rowData.location) && rowData.location
      //     ? ""
      //     : "Location is required";
      // },
    },
    // {
    //   title: "Age",
    //   field: "age",
    //   validate: (rowData) => {
    //     if (validateField(rowData.age)) {
    //     }

    //     return validateField(rowData.age) && rowData.age
    //       ? ""
    //       : "Age is required";
    //   },
    // },
  ];

  const [data, setData] = useState([]);

  const [backDropOpen, setBackDropOpen] = React.useState(true);
  useEffect(() => {
    getInactiveUsers().then((res) => {
      setData(res.data.users.rows);
      setBackDropOpen(false);
    });
  }, []);
  return (
    <div className="margindiv usersTable">
      <MaterialTable
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        icons={tableIcons}
        title="Inactive Users"
        columns={columns}
        data={data}
        actions={[
          {
            icon: DoneOutlineIcon,
            tooltip: "Activate",
            onClick: (event, rowData) =>
              new Promise((resolve, reject) => {
                setBackDropOpen(true);
                activateUser(rowData.uuid).then((res) => {
                  const dataDelete = [...data];
                  const index = rowData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);
                  resolve();
                  setBackDropOpen(false);
                });
              }),
          },
        ]}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              hardDeleteUser(oldData.uuid).then((res) => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                resolve();
              });
            }),
        }}
      />
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default Support;
