import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LOADING_MESSAGE, SET_MESSAGE } from "../../actions/types";
import AuthService from "../../services/auth.service";
import SigninImage from "../images/avatar.png";

let repPass = "";
const MyLink = (props) => <Link to="/login" {...props} />;

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.message, shallowEqual);

  const [email, setEmail] = useState("");
  const [successful, setSuccessful] = useState(false);

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== repPass) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("isTruthy", (value) => value);
  }, []);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    AuthService.forgotPassword(email)
      .then((res) => {
        setSuccessful(true);
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message || error?.message || error?.toString();
        setSuccessful(false);
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, severity: "error", isOpen: true },
        });
      });
  };

  return (
    <>
      <div className="MainLoginBg"></div>
      <div className="SigninWrapp">
        <div className="SigninTitle">
          <div className="inlineBox inlineBoxOtp">
            <h2>Forgot Password</h2>
          </div>
          <div className="inlineBox inlineBoxForget text-right">
            <img src={SigninImage} alt="" />
          </div>
        </div>
        {successful ? (
          <div className="text-center terms">
            <CheckCircleIcon style={{ color: "limegreen", fontSize: 100 }} />
            <h3>
              An email with a link to reset your password has been sent, please
              check your inbox.
            </h3>
          </div>
        ) : (
          <>
            <TabPanel value={0} index={0}>
              <ValidatorForm className="FormStyle" onSubmit={handleRegister}>
                <div style={{ marginBottom: 15 }}>
                  <label>Email</label>
                  <TextValidator
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "this field is required",
                      "email is not valid",
                    ]}
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    type="text"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>

                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn-block btnTheme"
                    disabled={isLoading}
                  >
                    Proceed
                  </Button>
                </div>
              </ValidatorForm>
            </TabPanel>
            <div className="text-center terms">
              <p>
                By continuing, you agree to metageo{" "}
                <Link target={"_blank"} to="/terms-of-service">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <a
                  target={"_blank"}
                  href="https://www.metageo.io/privacy-policy"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="text-center terms dont">
              <p>
                Have an account?
                <Button component={MyLink} to="/login">
                  {" "}
                  Log In
                </Button>
              </p>
            </div>
          </>
        )}
      </div>
      <div className="Overlay"></div>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default ForgetPassword;
