import React from 'react'; 

const Eula = () => {
    return (
        <div  className="margindiv">
           Eula Content here
        </div>
    )
}

export default Eula