import React, { useEffect } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import ChartistGraph from "react-chartist";
import Backdrop from "@material-ui/core/Backdrop";
import { DatePicker } from "antd";
import { groupBy, sumBy } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import BusinessIcon from "@material-ui/icons/Business";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import PersonIcon from "@material-ui/icons/Person";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import { getDashCounts, getUserActivity } from "../../services/user.service";
// core components
import GridItem from "./subcomponents/Grid/GridItem.js";
import GridContainer from "./subcomponents/Grid/GridContainer.js";
import Table from "./subcomponents/Table/Table.js";
import Tasks from "./subcomponents/Tasks/Tasks.js";
import CustomTabs from "./subcomponents/CustomTabs/CustomTabs.js";
import Danger from "./subcomponents/Typography/Danger.js";
import { Link } from "react-router-dom";
import Card from "./subcomponents/Card/Card.js";
import CardHeader from "./subcomponents/Card/CardHeader.js";
import CardIcon from "./subcomponents/Card/CardIcon.js";
import CardBody from "./subcomponents/Card/CardBody.js";
import CardFooter from "./subcomponents/Card/CardFooter.js";
import "../assets/css/material-dashboard-react.css";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "./subcomponents/variables";
import styles from "../assets/jss/material-dashboard-react/views/dashboardStyle.js";
const { RangePicker } = DatePicker;
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const [backDropOpen, setBackDropOpen] = React.useState(true);
  const [dashCounts, setDashCounts] = React.useState({});
  const [dashActivity, setDashActivity] = React.useState(null);
  const getActivity = (d1, d2) => {
    getUserActivity(d1, d2).then((res) => {
      let fileData = [];
      res.data.activeUsersData.forEach((data) => {
        if (data.action.toLowerCase().includes("file uploaded: ")) {
          data.fileType = data.action.toLowerCase().split(".")[
            (data.fileType = data.action.toLowerCase().split(".").length - 1)
          ];
          fileData.push(data);
        }
      });
      let actions = groupBy(
        res.data.activeUsersData.filter(
          (item) => !item.action.toLowerCase().includes("file uploaded: ")
        ),
        "action"
      );
      actions["Uploads"] = fileData;
      let actionsKeys = Object.keys(actions);
      let actionsSeries = [];
      Object.keys(actions).forEach((action) => {
        const sum = sumBy(actions[action], (obj) => {
          return parseInt(obj.count);
        });
        actionsSeries.push(sum);
      });
      let months = groupBy(res.data.activeUsers, "month");
      let monthKeys = Object.keys(months);
      let monthsSeries = [];
      Object.keys(months).forEach((action) => {
        const sum = sumBy(months[action], (obj) => {
          return parseInt(obj.count);
        });
        monthsSeries.push(sum);
      });
      let Files = groupBy(fileData, "fileType");
      setDashActivity({
        userLoginsData: res.data.userLoginsData,
        userSignupsData: res.data.userSignupsData,
        monthKeys,
        monthsSeries,
        actionsKeys,
        actionsSeries,
        Files,
        fileData: groupBy(fileData, "month"),
      });
    });
  };
  useEffect(() => {
    getActivity(
      new Date().getFullYear() + "-01",
      new Date().getFullYear() + "-12"
    );
    getDashCounts().then((res) => {
      setDashCounts(res.data);
      setBackDropOpen(false);
    });
  }, []);
  const classes = useStyles();
  return (
    <div className="margindiv">
      <GridContainer>
        {/* <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Used Space</p>
              <h3 className={classes.cardTitle}>
                49/50 <small>GB</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Get more space
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Revenue</p>
              <h3 className={classes.cardTitle}>$34,245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Fixed Issues</p>
              <h3 className={classes.cardTitle}>75</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Tracked from Github
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
        <GridItem xs={12} sm={6} md={3}>
          <Link to="/Users" style={{ textDecoration: "none" }}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="success">
                  <PersonIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Active Users</p>
                <h3 className={classes.cardTitle}>{dashCounts.ActiveUsers}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Link to="/InactiveUsers" style={{ textDecoration: "none" }}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="warning">
                  <PersonOutlineIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Inactive Users</p>
                <h3 className={classes.cardTitle}>
                  {dashCounts.InactiveUsers}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Link to="/Admins" style={{ textDecoration: "none" }}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <SupervisorAccountIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Admins</p>
                <h3 className={classes.cardTitle}>{dashCounts.AdminCount}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Link to="/Users" style={{ textDecoration: "none" }}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="danger">
                  <BusinessIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Organizations</p>
                <h3 className={classes.cardTitle}>
                  {dashCounts.OrganizationCount}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
      <h4 className={classes.cardTitle} style={{ textAlign: "center" }}>
        Dates:{" "}
        <RangePicker
          onChange={(a, b, c) => {
            getActivity(b[0], b[1]);
          }}
          picker="month"
        />
      </h4>
      <GridContainer>
        {dashActivity && (
          <GridItem xs={12} sm={12} md={6}>
            <Card chart>
              <CardHeader color="success">
                <ChartistGraph
                  className="ct-chart"
                  data={{
                    labels: Object.keys(
                      groupBy(dashActivity.userLoginsData, "month")
                    ),
                    series: [
                      dashActivity.userLoginsData?.map((itemLogin) => {
                        return itemLogin.count;
                      }),
                    ],
                  }}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Login Stats</h4>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        )}
        {dashActivity && (
          <GridItem xs={12} sm={12} md={6}>
            <Card chart>
              <CardHeader color="success">
                <ChartistGraph
                  className="ct-chart"
                  data={{
                    labels: Object.keys(
                      groupBy(dashActivity.userSignupsData, "month")
                    ),
                    series: [
                      dashActivity.userSignupsData?.map((itemLogin) => {
                        return itemLogin.count;
                      }),
                    ],
                  }}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Signups Stats</h4>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        )}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: ["Active", "Inactive", "Admins", "Total"],
                  series: [
                    [
                      dashCounts.ActiveUsers,
                      dashCounts.InactiveUsers,
                      dashCounts.AdminCount,
                      dashCounts.TotalUsers,
                    ],
                  ],
                }}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Users Stats</h4>
              {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase
              </p> */}
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {dashActivity && (
          <GridItem xs={12} sm={12} md={6}>
            <Card chart>
              <CardHeader color="success">
                <ChartistGraph
                  className="ct-chart"
                  data={{
                    labels: Object.keys(dashActivity.fileData),
                    series: [
                      Object.keys(dashActivity.fileData).map((action) => {
                        const sum = sumBy(
                          dashActivity.fileData[action],
                          (obj) => {
                            return parseInt(obj.count);
                          }
                        );
                        return sum;
                      }),
                    ],
                  }}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Upload Stats</h4>
                {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase
              </p> */}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        )}
        {/* <GridItem xs={12} sm={12} md={3}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Tasks</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
      </GridContainer>
      {dashActivity && (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card chart>
                <CardHeader color="success">
                  <ChartistGraph
                    className="ct-chart"
                    data={{
                      labels: dashActivity?.monthKeys,
                      series: [dashActivity?.monthsSeries],
                    }}
                    type="Line"
                    options={dailySalesChart.options}
                    listener={dailySalesChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>
                    Users activity by Months
                  </h4>
                  {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase
              </p> */}
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> updated 4 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card chart>
                <CardHeader color="success">
                  <ChartistGraph
                    className="ct-chart"
                    data={{
                      labels: dashActivity?.actionsKeys,
                      series: [dashActivity?.actionsSeries],
                    }}
                    type="Line"
                    options={dailySalesChart.options}
                    listener={dailySalesChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>
                    Users activity by actions
                  </h4>
                  {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase
              </p> */}
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> updated 4 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {Object.keys(dashActivity.Files).map((group) => (
              <GridItem xs={12} sm={12} md={6}>
                <Card chart>
                  <CardHeader color="success">
                    <ChartistGraph
                      className="ct-chart"
                      data={{
                        labels: Object.keys(
                          groupBy(dashActivity.Files[group], "month")
                        ),
                        series: [
                          Object.keys(
                            groupBy(dashActivity.Files[group], "month")
                          ).map((action) => {
                            const sum = sumBy(
                              groupBy(dashActivity.Files[group], "month")[
                                action
                              ],
                              (obj) => {
                                return parseInt(obj.count);
                              }
                            );
                            return sum;
                          }),
                        ],
                      }}
                      type="Line"
                      options={dailySalesChart.options}
                      listener={dailySalesChart.animation}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}>{group} uploads</h4>
                    {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase
              </p> */}
                  </CardBody>
                  <CardFooter chart>
                    <div className={classes.stats}>
                      <AccessTime /> updated 4 minutes ago
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </>
      )}
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
}
