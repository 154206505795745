import React, { useEffect } from "react";
import { forwardRef } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";
import Check from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import Search from "@material-ui/icons/Search";
import DescriptionIcon from "@material-ui/icons/Description";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getFileLogs } from "../../services/user.service";
import { Avatar, DatePicker } from "antd";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Slide,
  Tooltip,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
const blackColor = "#000";
const hexToRgb = (input) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase();
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  tooltip: {
    padding: "10px 15px",
    minWidth: "130px",
    lineHeight: "1.7em",
    border: "none",
    borderRadius: "3px",
    boxShadow:
      "0 8px 10px 1px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 3px 14px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 5px 5px -3px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    maxWidth: "200px",
    textAlign: "center",
    fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    textShadow: "none",
    textTransform: "none",
    letterSpacing: "normal",
    wordBreak: "normal",
    wordSpacing: "normal",
    wordWrap: "normal",
    whiteSpace: "normal",
    lineBreak: "auto",
  },
});
const { RangePicker } = DatePicker;
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  save: forwardRef((props, ref) => <DoneOutlineIcon {...props} ref={ref} />),

  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validateField(val) {
  return val != "";
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Support = () => {
  const classes = useStyles();
  const { useState } = React;
  const columns = [
    {
      title: "Status",
      width: "10%",
      render: (rowData) => {
        let doneCheck = rowData.file_change_histories.findLastIndex(
          (item) => item.operation === "Done"
        );
        let completeCheck = rowData.file_change_histories.findLastIndex(
          (item) => item.operation === "Complete"
        );
        let failCheck = rowData.file_change_histories.findLastIndex(
          (item) => item.operation === "Failed"
        );
        let successCheck =
          doneCheck > completeCheck ? doneCheck : completeCheck;
        return (
          <IconButton
            style={{ padding: 2 }}
            onClick={() => {
              setUserModal(true);
              setUsers(rowData);
            }}
          >
            {successCheck == -1 && failCheck == -1 ? (
              <InfoIcon />
            ) : successCheck > failCheck ? (
              <CheckCircleIcon
                style={{
                  color: "#4BB543",
                }}
              />
            ) : (
              <CancelIcon
                style={{
                  color: "red",
                }}
              />
            )}
          </IconButton>
        );
      },
      // disabled:rowData.isVerified?true:false,
    },
    {
      title: "File Name",
      field: "fileName",
      editable: "never",
    },
    {
      title: "Email",
      render: (col) => (
        <Link
          to={`/user-profile?user=${col.file_change_histories[0].userEmail}`}
        >
          {col.file_change_histories[0].userEmail}
        </Link>
      ),
      field: "file_change_histories[0].userEmail",
      editable: "never",
    },
    // {
    //   title: "Account Type",
    //   field: "actionType",
    //   editable: "never",
    // },

    // {
    //   title: "Action",
    //   field: "action",
    //   editable: "never",
    // },
    // {
    //   title: "Status",
    //   field: "status",
    //   lookup: {
    //     Pending: "Pending",
    //     "In Progress": "In Progress",
    //     Resolved: "Resolved",
    //   },
    // },
    {
      title: "Date",
      editable: "never",
      field: "createdAt",
      render: (rowData) => {
        return (
          rowData.createdAt.split("T")[0] +
          " " +
          rowData.createdAt.split("T")[1].substring(0, 8)
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [adminView, setAdminView] = useState(
    window.location.href.includes("user=")
      ? window.location.href.split("user=")[1]
      : false
  );
  const [page, setPage] = useState(0);
  const [row, setRow] = useState(5);
  const [userModal, setUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState(
    window.location.href.includes("user=")
      ? window.location.href.split("user=")[1]
      : ""
  );
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  //   const [date, setDate] = useState({ start: "", end: "" });

  const [backDropOpen, setBackDropOpen] = React.useState(true);
  useEffect(() => {
    setBackDropOpen(true);
    getFileLogs(date1, date2, email, page + 1, row)
      .then((res) => {
        setTotalItems(res.data.paginationInfo.totalItems);
        setData(res.data.fileLogs.rows);
        setBackDropOpen(false);
      })
      .catch(() => {
        setTotalItems(0);
        setData([]);
        setBackDropOpen(false);
      });
  }, []);
  return (
    <div className="margindiv usersTable">
      <MaterialTable
        page={page}
        // actions={[
        //   {
        //     icon: InfoIcon,
        //     // disabled:rowData.isVerified?true:false,
        //     tooltip: "Actions list",
        //     onClick: (event, rowData) => {
        //       setUserModal(true);
        //       setUsers(rowData);
        //     },
        //   },
        // ]}
        onChangePage={(page, size) => {
          setPage(page);
          setBackDropOpen(true);
          getFileLogs(date1, date2, email, page + 1, size)
            .then((res) => {
              setTotalItems(res.data.paginationInfo.totalItems);
              setData(res.data.fileLogs.rows);
              setBackDropOpen(false);
            })
            .catch(() => {
              setTotalItems(0);
              setData([]);
              setBackDropOpen(false);
            });
        }}
        onChangeRowsPerPage={(page, size) => {
          setRow(size);
          setPage(0);
          setBackDropOpen(true);
          getFileLogs(date1, date2, email, 0 + 1, size)
            .then((res) => {
              setTotalItems(res.data.paginationInfo.totalItems);
              setData(res.data.fileLogs.rows);
              setBackDropOpen(false);
            })
            .catch(() => {
              setTotalItems(0);
              setData([]);
              setBackDropOpen(false);
            });
        }}
        // editable={{
        //   onRowUpdate: (newData, oldData) => {
        //     return new Promise((resolve, reject) => {
        //       updateIssue(newData.status, newData.id).then((res) => {
        //         const dataUpdate = [...data];
        //         const index = oldData.tableData.id;
        //         dataUpdate[index] = newData;
        //         setData([...dataUpdate]);

        //         resolve();
        //       });
        //     });
        //   },
        //   // onRowDelete: (oldData) =>
        //   //   new Promise((resolve, reject) => {
        //   //     deleteUser(oldData.uuid).then((res) => {
        //   //       const dataDelete = [...data];
        //   //       const index = oldData.tableData.id;
        //   //       dataDelete.splice(index, 1);
        //   //       setData([...dataDelete]);
        //   //       resolve();
        //   //     });
        //   //   }),
        // }}
        options={{
          exportButton: true,
          exportAllData: true,
          search: false,
        }}
        icons={tableIcons}
        title={
          <>
            <span style={{ marginRight: 50 }} className="MuiTypography-h6">
              File Logs
            </span>
            <RangePicker
              onChange={(a, b, c) => {
                // setDate({ start: b[0], end: b[1] });
                setBackDropOpen(true);
                setDate1(b[0]);
                setDate2(b[1]);
                setPage(0);
                getFileLogs(b[0], b[1], email, 0 + 1, row)
                  .then((res) => {
                    setTotalItems(res.data.paginationInfo.totalItems);
                    setData(res.data.fileLogs.rows);
                    setBackDropOpen(false);
                  })
                  .catch(() => {
                    setTotalItems(0);
                    setData([]);
                    setBackDropOpen(false);
                  });
              }}
            />
            {adminView ? null : (
              <>
                <Input
                  style={{ marginLeft: 50, width: 200 }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      setPage(0);
                      setBackDropOpen(true);
                      getFileLogs(date1, date2, email, 0 + 1, row)
                        .then((res) => {
                          setTotalItems(res.data.paginationInfo.totalItems);
                          setData(res.data.fileLogs.rows);
                          setBackDropOpen(false);
                        })
                        .catch(() => {
                          setTotalItems(0);
                          setData([]);
                          setBackDropOpen(false);
                        });
                    }
                  }}
                  placeholder="Search Email"
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <IconButton
                  onClick={() => {
                    getFileLogs(date1, date2, email, page + 1, row)
                      .then((res) => {
                        setTotalItems(res.data.paginationInfo.totalItems);
                        setData(res.data.fileLogs.rows);
                        setBackDropOpen(false);
                      })
                      .catch(() => {
                        setTotalItems(0);
                        setData([]);
                        setBackDropOpen(false);
                      });
                  }}
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <Search />
                </IconButton>
              </>
            )}
          </>
        }
        columns={columns}
        data={data}
        totalCount={totalItems}
      />
      <Dialog
        open={userModal}
        TransitionComponent={Transition}
        onClose={() => setUserModal(false)}
        className="projectUsers"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Actions History"}</DialogTitle>
        <List style={{ height: 320, overflow: "auto" }}>
          {users?.file_change_histories?.map((user) => {
            return (
              <ListItem button key={user.email}>
                {user.operation == "Failed" && user.message ? (
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={user.message}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          padding: 3,
                        }}
                        className={classes.avatar}
                      >
                        <ErrorIcon
                          style={{
                            color: "#c73939",
                            animation: "rotateY 2s infinite linear",
                          }}
                        />
                      </Avatar>{" "}
                    </ListItemAvatar>
                  </Tooltip>
                ) : (
                  <ListItemAvatar>
                    <Avatar style={{ padding: 3 }} className={classes.avatar}>
                      <DescriptionIcon />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText
                  style={{ textTransform: "capitalize", fontWeight: "bolder" }}
                  primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                  primary={user.operation}
                />
                <ListItemText
                  style={{ marginLeft: 5 }}
                  primary={
                    "(" +
                    user.createdAt.split("T")[0] +
                    " " +
                    user.createdAt.split("T")[1].substring(0, 8) +
                    ")"
                  }
                />
              </ListItem>
            );
          })}
        </List>
        <DialogActions>
          <Button onClick={() => setUserModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default Support;
