import React from "react";
import AddressIcon from "../images/address.png";
import CallIcon from "../images/phoneIcon.png";
import { Router, Switch, Route, Link, withRouter } from "react-router-dom";
import LogoSite from "../images/logo.png";
import "../styles/mapview.css";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="FooterWrapp">
        <div className="FooterLogo">
          <img src={LogoSite} />
        </div>
        <div className="MenuBlock1">
          {/* <ul>
            <li>
              <Link to={"/SiteMap"} className="nav-link">
                Site Map{" "}
              </Link>
            </li>

            <li>
              <Link to={"/Termsuse"} className="nav-link">
                Termsuse
              </Link>
            </li>
            <li>
              <Link to={"/EULA"} className="nav-link">
                Site EULA
              </Link>
            </li>
            <li>
              <Link to={"/Users"} className="nav-link">
                Support
              </Link>
            </li>
          </ul> */}
        </div>
        <div className="MenuBlock2">
          <ul>
            <li>
              <a href="#.">
                <img src={AddressIcon} /> <span>Addresss</span>
              </a>
            </li>
            <li>
              <a href="#.">
                <img src={CallIcon} /> <span>Phone</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
