import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { SET_MESSAGE } from "../../actions/types";
import AuthService from "../../services/auth.service";
import SigninImage from "../images/avatar.png";

let repPass = "";

const AdminUserPassword = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [key, setKey] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  useEffect(() => {
    let key1 =
      history.location.pathname.split("/")[
        history.location.pathname.split("/").length - 1
      ];
    setKey(key1);
    dispatch({
      type: SET_MESSAGE,
      payload: null,
    });
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== repPass) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("isTruthy", (value) => value);
  }, []);

  const onChangePassword = (e) => {
    console.log(password ? password : "");
    repPass = e.target.value;
    const password1 = e.target.value;
    setPassword(password1);
  };
  const onChangeRepeatPassword = (e) => {
    const password2 = e.target.value;
    setRepeatPassword(password2);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    AuthService.adminUserPassword(key, password)
      .then((res) => {
        history.push("/login");
        window.location.reload();
      })
      .catch(() => {
        dispatch({
          type: SET_MESSAGE,
          payload: "Token Expired",
        });
      });
  };

  return (
    <>
      <div className="MainLoginBg"></div>
      <div className="SigninWrapp">
        <div className="SigninTitle">
          <div className="inlineBox">
            <h2>Set Password</h2>
          </div>
          <div className="inlineBox text-right">
            <img src={SigninImage} alt="" />
          </div>
        </div>
        <TabPanel value={0} index={0}>
          <ValidatorForm className="FormStyle" onSubmit={handleRegister}>
            <div>
              <label>Password</label>
              <TextValidator
                placeholder="Password"
                fullWidth
                onChange={onChangePassword}
                name="password"
                type="password"
                validators={[
                  "required",
                  "matchRegexp:^(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,}$",
                ]}
                errorMessages={[
                  "this field is required",
                  "Password should be minimum eight characters, at least one letter and one number",
                ]}
                value={password}
              />
            </div>
            <div>
              <label>Confirm Password</label>
              <TextValidator
                placeholder="Confirm Password"
                fullWidth
                onChange={onChangeRepeatPassword}
                name="repeatPassword"
                type="password"
                validators={["isPasswordMatch", "required"]}
                errorMessages={["password mismatch", "this field is required"]}
                value={repeatPassword}
              />
            </div>
            <div>
              <Button
                type="submit"
                variant="contained"
                className="btn-block btnTheme"
              >
                Save
              </Button>
            </div>
          </ValidatorForm>
        </TabPanel>
        <div className="text-center terms">
          <p>
            By continuing, you agree to metageo{" "}
            <Link target={"_blank"} to="/terms-of-service">
              Terms of Service
            </Link>{" "}
            and{" "}
            <a target={"_blank"} href="https://www.metageo.io/privacy-policy">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
      <div className="Overlay"></div>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default AdminUserPassword;
