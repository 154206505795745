import React from 'react';
import Facebook from '../images/facebook.png';
import GooglePlus from '../images/googlePlus.png';
import Twitter from '../images/twitter.png';
import RSS from '../images/rss.png';

const CopyRight = () => {
    return (
        <div className="CopyRIghtBg">
            <div className="rightText">COPYRIGHT 2022   METAGEO . ALL RIGHTS RESERVED</div>
            <ul>
                <li><a href="#."><img src={Facebook} /></a></li>
                <li><a href="#."><img src={GooglePlus} /></a></li>
                <li><a href="#."><img src={Twitter} /></a></li>
                <li><a href="#."><img src={RSS} /></a></li>
            </ul>
        </div>
    )
}

export default CopyRight