import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const InputValidator = (props) => {
  const [textValState, setTextValState] = useState({
    name: props.displayItemsName,
  });

  const handleSubmitEdit = () => {
    props.handleSubmitEdit(textValState);
  };
  return (
    <>
      <ValidatorForm onSubmit={handleSubmitEdit} debounceTime={1500}>
        <DialogContent>
          <TextValidator
            label={props.label}
            name={props.name}
            autoFocus
            value={textValState.name}
            onChange={(event) => {
              setTextValState({ ...textValState, name: event.target.value });
            }}
            fullWidth
            validators={props.validators}
            errorMessages={props.errorMessages}
            //validators={["required", "matchRegexp:^[A-Za-z0-9_.\\s]+$"]}
            // errorMessages={[
            //   "Name is required",
            //   "Special Chrachters are not allowed",
            // ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button
            // onClick={handleCloseFolderDialog}
            type="submit"
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </ValidatorForm>
    </>
  );
};

export default InputValidator;
