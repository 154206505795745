import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useRef, useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../actions/auth";
import {
  LOADING_MESSAGE,
  ORGANIZATIONS,
  SET_MESSAGE,
} from "../../actions/types";
import {
  deleteUserSelf,
  getAdminUser,
  getAllOrgs,
  getUserDetail,
  stripeManage,
  updateUser,
} from "../../services/user.service";
import { displayMessage, industries } from "../common/common.js";
import SigninImage from "../images/avatar.png";
import { useHistory } from "react-router-dom";
import Input from "antd/lib/input/Input";
import { Modal } from "antd";
import secureLocalStorage from "react-secure-storage";
import ErrorLogs from "./ErrorLogs.js";
import Support from "./FileLogs.js";
let deleteConfirm = "";
const AdminUserProfile = () => {
  const { userTypes, organizations } = useSelector((state) => state.auth);
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.message);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    storageCapacity: 0,
    country: "",
    state: "",
    city: "",
    typeID: null,
    industry: "",
    company: "",
    isOtpEnabled: false,
    showTooltips: false,
    isOrganizationAdmin: null,
    organizationID: null,
    isVerified: null,
  });

  const changeInputHandler = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };
  useEffect(() => {
    if (searchInput.current) searchInput.current.focus();
  }, [search]);
  useEffect(() => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    getAdminUser(window.location.href.split("user=")[1])
      .then((data) => {
        if (data.status === 200) {
          const user = data?.data?.user;
          setFormData({ ...formData, ...user });
        }
        dispatch({ type: LOADING_MESSAGE, isLoading: false });
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message || error?.message || error?.toString();
        dispatch({
          type: SET_MESSAGE,
          payload: {
            message: "User does not exists",
            severity: "error",
            isOpen: true,
          },
        });
      });
    !organizations &&
      getAllOrgs().then((res) => {
        let types = {};
        res.data.organizations.forEach((singletype) => {
          types[singletype.organizationID] = singletype.organizationName;
        });
        types[null] = "No Organization";
        dispatch({
          type: ORGANIZATIONS,
          payload: types,
        });
      });
  }, []);

  const handleUpdate = (e) => {
    e.preventDefault();

    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    new Promise((resolve, reject) => {
      if (
        formData.firstName != "" &&
        formData.lastName != "" &&
        // formData.age != "" &&
        formData.company != ""
      ) {
        try {
          updateUser(formData.uuid, {
            country: formData.country,
            state: formData.state,
            city: formData.city,
            industry: formData.industry,
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            company: formData.company,
            typeID: Number(formData.typeID),
            organizationID: formData.organizationID
              ? Number(formData.organizationID)
              : null,
            isOrganizationAdmin: formData.organizationID
              ? formData.isOrganizationAdmin !== "false" &&
                formData.isOrganizationAdmin
                ? true
                : false
              : false,
            storageCapacity: formData.storageCapacity,
            isVerified: formData.isVerified,
          }).then((res) => {
            resolve();
            dispatch({ type: LOADING_MESSAGE, isLoading: false });
            dispatch(displayMessage("success", "User updated successfully"));
          });
        } catch (error) {
          dispatch({ type: LOADING_MESSAGE, isLoading: false });
          dispatch(displayMessage("error", "Error in updating user"));
        }
      } else {
        reject();
      }
    });
  };

  return (
    <>
      <div className="MainUserAdminBG"></div>
      <div
        className="AdminUserWrapp"
        style={{ overflow: "auto", height: "auto" }}
      >
        <div className="SigninTitle">
          <div className="inlineBox">
            <h2>Update User Info</h2>
          </div>
          <div className="inlineBox text-right">
            <img src={SigninImage} alt="" />
          </div>
        </div>
        <TabPanel value={0} index={0}>
          <ValidatorForm className="FormStyle" onSubmit={handleUpdate}>
            <div>
              <label>Email</label>
              <TextValidator
                disabled
                validators={["isEmail"]}
                errorMessages={["this field is required", "email is not valid"]}
                name="email"
                value={formData.email}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div>
              <label>First name</label>
              <TextValidator
                validators={["required"]}
                errorMessages={["this field is required"]}
                name="firstName"
                value={formData.firstName}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="First name"
              />
            </div>
            <div>
              <label>Last name</label>
              <TextValidator
                validators={["required"]}
                errorMessages={["this field is required"]}
                name="lastName"
                value={formData.lastName}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="Last name"
              />
            </div>

            <div>
              <label>Industry</label>
              <TextValidator
                id="standard-select-currency"
                select
                onChange={changeInputHandler}
                variant="standard"
                validators={["required"]}
                errorMessages={["This field is required."]}
                name="industry"
                value={formData.industry}
                className="form-control"
              >
                {industries.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextValidator>
            </div>
            <div>
              <label>Country</label>
              <CountryDropdown
                style={{ fontFamily: "revert" }}
                value={formData.country}
                className="form-control"
                defaultOptionLabel="Select Country"
                onChange={(value) =>
                  setFormData({ ...formData, country: value })
                }
              />
            </div>
            <div>
              <label>State</label>
              <RegionDropdown
                style={{ fontFamily: "revert" }}
                country={formData.country}
                value={formData.state}
                className="form-control"
                defaultOptionLabel="Select State"
                onChange={(value) => setFormData({ ...formData, state: value })}
              />
            </div>
            <div>
              <label>City</label>
              <TextValidator
                // validators={["required"]}
                // errorMessages={["This field is required."]}
                name="city"
                value={formData.city}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="City"
              />
            </div>
            <div>
              <label>Company</label>
              <TextValidator
                validators={["required"]}
                errorMessages={["this field is required"]}
                name="company"
                value={formData.company}
                onChange={changeInputHandler}
                className="form-control"
                placeholder="Company name"
              />
            </div>
            <div>
              <label>Storage(GB)</label>
              <TextValidator
                // validators={["required"]}
                errorMessages={["this field is required"]}
                name="storageCapacity"
                min={0}
                disabled={
                  formData.organizationID && formData.organizationID !== "null"
                  //  &&!formData.isOrganizationAdmin
                }
                inputProps={{ min: 0 }}
                value={
                  formData.organizationID && formData.organizationID !== "null"
                    ? // &&!formData.isOrganizationAdmin
                      "Handled by Organization"
                    : formData.storageCapacity / 1024
                }
                // value={formData.storageCapacity}
                onChange={(e) => {
                  const { value } = e.target;
                  setFormData({ ...formData, storageCapacity: value * 1024 });
                }}
                type={
                  formData.organizationID && formData.organizationID !== "null"
                    ? // &&!formData.isOrganizationAdmin
                      "text"
                    : "number"
                }
                className="form-control"
                placeholder="Storage"
              />
            </div>
            <div>
              <label>User Type</label>
              <TextValidator
                id="standard-select-currency"
                select
                onChange={changeInputHandler}
                variant="standard"
                validators={["required"]}
                errorMessages={["This field is required."]}
                name="typeID"
                value={formData.typeID}
                className="form-control"
              >
                {userTypes &&
                  Object.keys(userTypes).map((option) => (
                    <MenuItem key={option} value={option}>
                      {userTypes[option]}
                    </MenuItem>
                  ))}
              </TextValidator>
            </div>
            <div>
              <label>Organization</label>
              <TextValidator
                id="standard-select-currency"
                select
                onChange={changeInputHandler}
                variant="standard"
                validators={["required"]}
                errorMessages={["This field is required."]}
                name="organizationID"
                value={formData.organizationID}
                className="form-control"
              >
                <MenuItem
                  onKeyDown={(e) => e.stopPropagation()}
                  style={{ padding: 0 }}
                  key={0}
                  value={0}
                >
                  <Input
                    ref={searchInput}
                    placeholder="Search"
                    value={search}
                    className="searchInput"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSearch(e.target.value);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  />
                </MenuItem>
                {organizations &&
                  Object.keys(organizations)
                    .filter((item) =>
                      organizations[item]
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    .map((option) => (
                      <MenuItem
                        onKeyDown={(e) => e.stopPropagation()}
                        key={option}
                        value={option}
                      >
                        {organizations[option]}
                      </MenuItem>
                    ))}
              </TextValidator>
            </div>
            <div>
              <label>Role</label>
              <TextValidator
                id="standard-select-currency"
                select
                onChange={changeInputHandler}
                variant="standard"
                validators={["required"]}
                errorMessages={["This field is required."]}
                name="isOrganizationAdmin"
                value={formData.isOrganizationAdmin}
                className="form-control"
              >
                <MenuItem key={0} value={true}>
                  Admin
                </MenuItem>
                <MenuItem key={1} value={false}>
                  Member
                </MenuItem>
              </TextValidator>
            </div>
            <div>
              <label>Status</label>
              <TextValidator
                id="standard-select-currency"
                select
                onChange={changeInputHandler}
                variant="standard"
                validators={["required"]}
                errorMessages={["This field is required."]}
                name="isVerified"
                value={formData.isVerified}
                className="form-control"
              >
                <MenuItem key={0} value={1}>
                  Verified
                </MenuItem>
                <MenuItem key={1} value={0}>
                  Unverified
                </MenuItem>
              </TextValidator>
            </div>
            {/* <div>
              <label>User Type</label>
              <TextValidator
                disabled
                name="userType"
                value={"Free"}
                onChange={changeInputHandler}
                className="form-control"
                placeholder="Company name"
              />
            </div> */}
            <div>
              <Button
                type="submit"
                variant="contained"
                className="btn-block btnTheme"
              >
                Save
              </Button>
            </div>
          </ValidatorForm>
        </TabPanel>
      </div>
      <div
        style={{
          marginTop: 100,
          width: "100%",
          display: "inline-block",
        }}
      >
        <Support />
      </div>
      <div
        style={{
          marginTop: -80,
          marginBottom: 170,
          width: "100%",
          display: "inline-block",
        }}
      >
        <ErrorLogs />
      </div>
      <Backdrop open={isLoading} style={{ zIndex: 99999999999, color: "#fff" }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default AdminUserProfile;
