import React from 'react'; 

const About = () => {
    return (
        <div  className="margindiv">
           About Content here
        </div>
    )
}

export default About